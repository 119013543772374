import axios from "axios";

import { networkConfig } from "./networkConfig";
import { Message } from "element-ui";
// 创建axios实例
export function requestService(config) {
  const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: networkConfig.serverUrl,
    // 超时
    timeout: networkConfig.requestTimeout,
  });
  // request拦截器
  service.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
  // 响应拦截器
  service.interceptors.response.use(
    (response) => {
      const res = response.data;
      if (res.code != 0) {
        // return Promise.reject(new Error(res.msg || "Error"));

        if ((res.http ?? "") !== "") {
          console.log(999999999, res.http);
          //token失效 重新登录
          let locationurl = document.location.href;
          let url_obj = locationurl.split("#");

          window.location.href = `${res.http}?redirect-url-succ=${url_obj[0]}&redirect-url-name=${url_obj[1]}`;
        }
        Message({
          showClose: true,
          message: res.msg,
          type: "error",
        });
      }

      return res;
    },
    (error) => {
      if (
        error.message == `timeout of ${networkConfig.requestTimeout}ms exceeded`
      ) {
        Message.error("请求超时！");
        return Promise.reject(error);
      }
      if (error.response.status == 404) {
        Message.error("找不到请求接口");
        return Promise.reject(error);
      }
    }
  );
  return service(config);
}
