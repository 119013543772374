import { requestService } from "../request.js";
import { getCookie } from "@/utils/auth";
const VITE_APP_API = "api/";
// 首页信息
export function get_home() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}home/page`,
    method: "post",
    data: data,
  });
}
// 导航栏信息
export function get_navi_bar() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}navi/bar2`,
    method: "post",
    data: data,
  });
}
// 常见问题
export function get_com_prob() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}com/prob`,
    method: "post",
    data: data,
  });
}
//列表页
export function get_prod_page() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}prod/page`,
    method: "post",
    data: data,
  });
}

export function Newcarousel() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}newcarousel`,
    method: "post",
    data: data,
  });
}
// 首页推荐信息
export function SugInfo() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}suginfo`,
    method: "post",
    data: data,
  });
}
// 官网首页应用领域
export function Areainfo() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}areainfo`,
    method: "post",
    data: data,
  });
}
export function hp_carousel(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}hp_carousel`,
    method: "post",
    data: data,
  });
}
// 头部配置信息
export function Config(local) {
  const data = {
    local,
  };
  return requestService({
    url: `${VITE_APP_API}config`,
    method: "post",
    data: data,
  });
}
// 定位服务详情
export function Serve(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}serve`,
    method: "post",
    data: data,
  });
}
// 新闻信息
export function News(pcount) {
  const data = {
    pcount,
  };
  return requestService({
    url: `${VITE_APP_API}news`,
    method: "post",
    data: data,
  });
}
// 定位服务列表
export function serve_list() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}serve_list`,
    method: "post",
    data: data,
  });
}
// 职位信息
export function Offer(jkds, jcity, jall) {
  const data = {
    // jkds: "技术类",
    jkds,
    jcity,
    jall,
  };
  return requestService({
    url: `${VITE_APP_API}offer`,
    method: "post",
    data: data,
  });
}
// 搜索建议
export function Download(wtype, wmode, wall) {
  const data = {
    wtype,
    wmode,
    wall,
  };
  return requestService({
    url: `${VITE_APP_API}download`,
    method: "post",
    data: data,
  });
}
// 模组列表页
export function modulelist() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}module_list`,
    method: "post",
    data: data,
  });
}
// 模组产品页
export function Module(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}module`,
    method: "post",
    data: data,
  });
}

export function Uavlist() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}uav_list`,
    method: "post",
    data: data,
  });
}
// 职位搜索信息
export function Suggarea(area, key) {
  const data = {
    area,
    key,
  };
  return requestService({
    url: `${VITE_APP_API}sugg`,
    method: "post",
    data: data,
  });
}
// 解决方案列表页
export function Resolve() {
  const data = {};
  return requestService({
    url: `${VITE_APP_API}resolve`,
    method: "post",
    data: data,
  });
}
// 联系我们表单
export function Psuggest(fname, ftel, fcorname, faddress, fmail, fbeint) {
  const data = {
    fname,
    ftel,
    fcorname,
    faddress,
    fmail,
    fbeint,
  };
  return requestService({
    url: `${VITE_APP_API}psuggest`,
    method: "post",
    data: data,
  });
}
// 无人机产品页
export function Uav(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}uav`,
    method: "post",
    data: data,
  });
}
// RTK产品页
export function Rtk(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}rtk`,
    method: "post",
    data: data,
  });
}
// 新闻详情页
export function NearNews(date) {
  const data = {
    date,
  };
  return requestService({
    url: `${VITE_APP_API}nearNews`,
    method: "post",
    data: data,
  });
}
// 新闻详情页
export function NewsDetail(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}newsDetail`,
    method: "post",
    data: data,
  });
}
// 产品购买页信息
export function reqGoodsInfo(table, path) {
  const data = {
    table,
    path,
  };
  return requestService({
    url: `${VITE_APP_API}bgoods`,
    method: "post",
    data: data,
  });
}
// 基站页
export function StationData(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}station`,
    method: "post",
    data: data,
  });
}
// 惯导
export function Kinname(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}kin`,
    method: "post",
    data: data,
  });
}
// 产品
export function Api_product(path) {
  const data = {
    path,
  };
  return requestService({
    url: `${VITE_APP_API}product`,
    method: "post",
    data: data,
  });
}
// // 个人中心
// export function Personal() {
//   const data = {};
//   return requestService({
//     url: `sso/get/info?token=${getCookie(getCookie("username"))}`,
//     method: "post",
//     data: data,
//   });
// }
// // 个人中心信息修改
// export function Password(fpass, fvaldate, ftel, fcode, fname) {
//   const data = {
//     fpass,
//     fvaldate,
//     ftel,
//     fcode,
//     fname,
//   };
//   return requestService({
//     url: `sso/user/info?token=${getCookie(getCookie("username"))}`,
//     method: "post",
//     data: data,
//   });
// }
// export function VerificationCode(ftel) {
//   const data = {
//     ftel,
//     ftype: "3",
//   };
//   return requestService({
//     url: `sso/sms/check`,
//     method: "post",
//     data: data,
//   });
// }
// // 头像上传
// export function uploadPic() {
//   const data = {};
//   return requestService({
//     url: `sso/uploadPic`,
//     method: "post",
//     data: data,
//   });
// }

// // 个人认证
// export function Authentication({
//   fserpur,
//   ficd,
//   ficname,
//   ftype,
//   fcomname,
//   fcommai,
//   fcon,
//   fconphone,
//   fconaddress,
//   fbuslicense,
//   idcard,
//   servercard,
// }) {
//   const data = {
//     fserpur,
//     ficd,
//     ficname,
//     ftype,
//     fcomname,
//     fcommai,
//     fcon,
//     fconphone,
//     fconaddress,
//     fbuslicense,
//     idcard,
//     servercard,
//   };
//   return requestService({
//     // baseURL: "https://s.kplgnss.com/zjy/",
//     url: `sso/authentication?token=${getCookie(getCookie("username"))}`,
//     method: "post",
//     data: data,
//   });
// }
// // 图片上传
// export function UploadOne() {
//   const data = {
//     ftype: "2",
//   };
//   return requestService({
//     url: `sso/uploadOne?token=${getCookie(getCookie("username"))}`,
//     method: "post",
//     data: data,
//   });
// }
// // 头像上传
// export function UploadPic(file) {
//   const data = {
//     file,
//   };
//   return requestService({
//     url: `sso/uploadPic?token=${getCookie(getCookie("username"))}`,
//     method: "post",
//     data: data,
//   });
// }
