import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store";
// import "fullpage.js/vendors/fullpage.min.css";
// import "fullpage.js/vendors/scrolloverflow";
// 全屏滚动 vue-fullpage.js
import VueLazyload from "vue-lazyload";
// import "fullpage.js/vendors/scrolloverflow";
// import VueFullPage from "vue-fullpage.js";
import "@/router/guards";
import "@/style/style.scss";
// WebSocket封装方法
import * as socketApi from "./utils/webSocket";
import '@/utils/vdown.js'
Vue.prototype.socketApi = socketApi;

// Vue.use(Base64);

// Vue.use(VueFullPage);

Vue.config.productionTip = false;
Vue.use(ElementUI);

Vue.use(VueLazyload, {
  // 添加自定义选项
  preLoad: 1.3,
  // error: "./assets/images/white.png", // 加载错误时候的图片
  // loading: "./assets/images/white.png", // 加载中的图片
  attempt: 1,
  listenEvents: [
    "scroll",
    "wheel",
    "mousewheel",
    "resize",
    "animationend",
    "transitionend",
    "touchmove",
  ],
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
