<template>
  <div class="head">
    <div id="nav-bar" :class="navShow ? 'navOn' : 'navOff'">
      <router-link to="/h5/home/index" style="width: 50%">
        <img class="images" src="../assets/acs/logo_1@3x.png" alt />
      </router-link>

      <i size="medium" style="margin-right: 5vw" class="el-icon-s-fold" @click="drawer = true"></i>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="100%"
      :with-header="false"
    >
      <div class="head-log">
        <div>
          <img class="images" style="width: 50%" src="../assets/acs/logo_1@3x.png" alt />
        </div>

        <i class="el-icon-close" @click="drawer = false"></i>
      </div>
      <div class="navigationbar">
        <div class="navigationbar-name">产品</div>
        <div class="navigationbar-one">
          <el-collapse v-model="activeName" v-for="(item, index) in productdate" :key="index">
            <el-collapse-item
              :title="item.ftitle"
              :name="index"
              style="padding-left: 6%; background-color: #fff"
            >
              <div class="navigationbar-two" v-for="item_data in item.flist" :key="item_data.path">
                <router-link
                  id="router-link"
                  @click.native="drawer = false"
                  :to="'/h5' +item_data.path"
                >{{ item_data.name }}</router-link>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="navigationbar-one">
          <el-collapse
            v-model="activeNames"
            v-for="(item, index) in solution"
            :key="index"
            style="padding-left: 4%; background-color: #fff"
          >
            <el-collapse-item :title="item.ftitle" :name="index">
              <div class="navigationbar-two" v-for="item_data in item.flist" :key="item_data.path">
                <router-link
                  id="router-link"
                  @click.native="drawer = false"
                  :to="item_data.path"
                >{{ item_data.name }}</router-link>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="navigationbar-one">
          <el-collapse
            v-model="activeNamesyservices"
            v-for="(item, index) in companyservices"
            :key="index"
          >
            <el-collapse-item
              :title="item.ftitle"
              :name="index"
              style="padding-left: 4%; background-color: #fff"
            >
              <div class="navigationbar-two" v-for="item_data in item.flist" :key="item_data.path">
                <router-link
                  id="router-link"
                  @click.native="drawer = false"
                  :to="item_data.path"
                >{{ item_data.name }}</router-link>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { get_navi_bar } from "../axios/modules/officialwebsite.js";

export default {
  data() {
    return {
      drawer: false,
      direction: "rtl",
      navShow: true,
      top: "",
      activeName: [],
      activeNames: [],
      activeNamesyservices: [],
      productdate: [],
      solution: [],
      companyservices: []
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
    this.Get_navi_bar();
  },
  methods: {
    handleClose(done) {
      done();
    },
    async Get_navi_bar() {
      let res = await get_navi_bar();

      if (res.code == 0) {
        // console.log(res.data.header);
        // this.headname = res.data.header.list;
        this.productdate = res.data.header.product;
        this.solution = res.data.header.service;
        this.companyservices = res.data.header.regardkpl;
        this.solution = [
          res.data.header.induApp[0],
          res.data.header.solution[0]
        ];
      }
    }
  },
  watch: {
    $route(toPath, fromPath) {
      this.navShow = true;
    },
    top(newValue, oldValue) {
      // console.log(1111);
      // 等新值大于100的时候再做变化（优化一下）
      if (newValue > 20) {
        if (newValue > oldValue) {
          this.navShow = false;
        } else {
          this.navShow = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.head {
  width: 100%;

  // z-index: 9999;
  // position: fixed;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 2%;
  // left: 0;
  // top: 0;
  // background: #fff;
}
.head-log {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
}
.images {
  width: 100%;
}
.navigationbar {
  width: 100%;
  background: #ebebeb;

  .navigationbar-name {
    line-height: 50px;
    padding-left: 4%;

    color: #333333;
    font-weight: bold;
  }
  .navigationbar-one {
    margin-bottom: 20px;
  }
}
.navigationbar-two {
  padding-left: 4%;
  border-bottom: 1px solid #d4d4d4;
  line-height: 42px;
  color: #666666;
  font-size: 12px;
}
#router-link {
  display: inline-block;
  width: 100%;
}

.navOn {
  width: 100%;
  z-index: 9;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  left: 0;
  top: 0;
  background: #fff;
  transition: all 0.3s ease-in-out 0.3s;
  transform: translateZ(0);
}
.navOff {
  width: 100%;
  z-index: 9;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  left: 0;
  top: 0;
  background: #fff;
  transition: all 0.3s ease-in-out 0.3s;
  transform: translate3d(0, -100%, 0);
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
::v-deep .el-collapse-item__header {
  border-bottom: 1px solid #d4d4d4;
  // background: #ebebeb;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}
::v-deep .el-collapse {
  border-top: none;
}
</style>
