var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"head-top"},[_c('div',{staticClass:"head-top__center"},[_c('div'),_c('div',{staticClass:"head-top__title"},[_c('div',{staticClass:"head-top__text"}),(_vm.youname == false)?_c('div',{staticClass:"head-top__text",on:{"click":_vm.gologin}},[_c('img',{staticClass:"head-icon",attrs:{"src":require("../icons/svg/denglu.svg"),"alt":""}}),_vm._v("登录/注册 ")]):_vm._e(),(_vm.youname == true)?_c('div',{staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"}},[_c('i',{staticClass:"el-icon-user-solid",staticStyle:{"margin-right":"5px"}}),_c('div',[_c('p',{on:{"click":_vm.gopersonal}},[_vm._v(_vm._s(_vm.username))]),_c('p')])]):_vm._e()])])]),_c('div',{staticClass:"head-center"},[_c('div',{staticClass:"head-log",on:{"click":_vm.gotohome}}),_c('div',{staticClass:"head-right"},_vm._l((_vm.headname),function(item,index){return _c('div',{key:index,staticClass:"head-onenavigation",on:{"mouseover":function($event){(_vm.value = item.ftitle), (_vm.mask = true)},"mouseout":function($event){_vm.value = ''}}},[_c('div',{staticClass:"head-onenavigation__text",class:[{ active: _vm.value == item.ftitle }, 'nav-tab']},[(item.path)?_c('router-link',{class:[{ active: _vm.value == item.ftitle }, 'nav-texk'],attrs:{"to":item.path}},[_vm._v(_vm._s(item.ftitle))]):_c('div',[_vm._v(_vm._s(item.ftitle))]),(item.ftlist!==undefined&&item.ftlist.length>1)?_c('div',{staticClass:"head-twonavigation"},[_c('div',{staticClass:"head-twonavigation__li"},_vm._l((item.ftlist),function(itemlist){return _c('div',{key:itemlist.ftitle,staticClass:"head-twonavigation__right"},[_c('router-link',{staticClass:"head-twonavigation__head",attrs:{"to":{
                        path:itemlist.fpath,
                        query: { title: itemlist.ftitle },
                      }}},[_vm._v(_vm._s(itemlist.ftitle))]),_c('div',{staticClass:"head-twonavigation__hr"}),_c('div',{staticClass:"head-twonavigation__foot"},_vm._l((itemlist.flist),function(list_data){return _c('div',{key:list_data.path,staticClass:"right-row",on:{"mouseover":function($event){_vm.right_item_active = list_data.path}}},[_c('router-link',{class:[
                        { active: _vm.right_item_active == list_data.path },
                        'right-row--a',
                      ],attrs:{"to":list_data.path}},[_vm._v(_vm._s(list_data.name))])],1)}),0)],1)}),0)]):_vm._e()],1)])}),0),_c('div',{staticClass:"head-imput"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }