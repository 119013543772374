import { personalService } from "../personal.js";
import { getCookie } from "@/utils/auth";
// 个人中心
export function Personal() {
  const data = {};
  return personalService({
    url: `sso/get/info?token=${getCookie(getCookie("username"))}`,
    method: "post",
    data: data,
  });
}
// 个人中心信息修改
export function Password(fpass, fvaldate, ftel, fcode, fname) {
  const data = {
    fpass,
    fvaldate,
    ftel,
    fcode,
    fname,
  };
  return personalService({
    url: `sso/user/info?token=${getCookie(getCookie("username"))}`,
    method: "post",
    data: data,
  });
}
export function VerificationCode(ftel) {
  const data = {
    ftel,
    ftype: "3",
  };
  return personalService({
    url: `sso/sms/check`,
    method: "post",
    data: data,
  });
}
// 头像上传
export function uploadPic() {
  const data = {};
  return personalService({
    url: `sso/uploadPic`,
    method: "post",
    data: data,
  });
}

// 个人认证
export function Authentication({
  fserpur,
  ficd,
  ficname,
  ftype,
  fcomname,
  fcommai,
  fcon,
  fconphone,
  fconaddress,
  fbuslicense,
  idcard,
  servercard,
}) {
  const data = {
    fserpur,
    ficd,
    ficname,
    ftype,
    fcomname,
    fcommai,
    fcon,
    fconphone,
    fconaddress,
    fbuslicense,
    idcard,
    servercard,
  };
  return personalService({
    // baseURL: "https://s.kplgnss.com/zjy/",
    url: `sso/authentication?token=${getCookie(getCookie("username"))}`,
    method: "post",
    data: data,
  });
}
// 图片上传
export function UploadOne() {
  const data = {
    ftype: "2",
  };
  return personalService({
    url: `sso/uploadOne?token=${getCookie(getCookie("username"))}`,
    method: "post",
    data: data,
  });
}
// 头像上传
export function UploadPic(file) {
  const data = {
    file,
  };
  return personalService({
    url: `sso/uploadPic?token=${getCookie(getCookie("username"))}`,
    method: "post",
    data: data,
  });
}
