<template>
  <div class="footer-page">
    <div class="footer-title">
      <ul v-for="item in footer_list" :key="item.id">
        <p :text="item.ftitle">
          <strong>{{ item.ftitle }}</strong>
        </p>
        <div class="footer-li" v-if="item.flist">
          <li v-for="i in item.flist" :key="i.id">
            <router-link
              :to="{
                          path:i.path,
                          query: { title: i.title },
                        }"
            >{{ i.title }}</router-link>
          </li>
        </div>
        <div class="footer-about">
          <p class="footer-about__text">{{ item.text }}</p>
          <p class="footer-about__text">{{ item.text1 }}</p>
        </div>
        <div class="footer-account" v-if="item.listicon">
          <img :src="imageulr+item.listicon" alt />
          <!-- <p class="footer-icon"></p> -->
          <div class="footer-account__p">{{ item.listname }}</div>
        </div>
        <img v-if="item.listimg" class="footer-listimg" :src="imageulr+item.listimg" alt />
      </ul>
    </div>
    <div class="footer-text">
      <a
        rel="nofollow"
        target="_blank"
        href="https://beian.miit.gov.cn/"
      >Copyright © 开普勒卫星科技（武汉）有限公司 | 鄂ICP备2021013347号-2</a>
    </div>
  </div>
</template>
  
  <script>
// import { config } from "../axios/modules/officialwebsite.js";
import { get_navi_bar } from "../axios/modules/officialwebsite.js";
import { networkConfig } from "../axios/networkConfig";

export default {
  props: ["item", "name"],
  data() {
    return {
      footer_list: [],
      imageulr: networkConfig.serverUrl + "upload/"
    };
  },
  methods: {
    async Get_navi_bar() {
      let res = await get_navi_bar();
      if (res.code == 0) {
        // console.log(res.foot);
        this.footer_list = res.data.footer;
      }
    }
  },
  components: {
    // configlocal,
  },
  mounted() {
    this.Get_navi_bar();
  },

  //防止数据丢失
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  }
};
</script>
  
  <style lang="scss" scoped>
.footer-page {
  width: 100%;
  height: 530px;
  background-color: #f2f2f2;
  font-size: 18px;
}
.footer-title {
  width: 1320px;
  height: 350px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #999;
}
ul {
  margin-top: 20px;
}
p {
  font-size: 0.9375em;
  text-align: left;
  margin-bottom: 10px;
  color: #333;
  // font-weight: 200;
}
.footer-li {
  height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
li {
  width: 130px;
  list-style: none;
  text-align: left;
  color: #333333;
  padding: 12px 0px;
  font-size: 16px;
}
a {
  color: #595757;
  text-decoration: none;
}
a:hover {
  color: rgb(61, 107, 206);
}
.footer-about {
  list-style: none;
  text-align: left;

  padding-top: 10px;
  .footer-about__text {
    color: #595757;
    font-size: 0.8125em;
  }
}
.footer-account {
  display: flex;
  list-style: none;
  text-align: left;
  align-items: center;
  // justify-content: center;
  color: #595757;
  padding-top: 10px;
  margin-bottom: 20px;

  .footer-account__p {
    color: #595757;
    // line-height: 43px;
  }
  > img {
    height: 20px;
    margin-right: 10px;
  }
}
.footer-listimg {
  height: 100px;
}
.footer-text {
  padding: 20px;
  width: 1320px;
  margin: 0 auto;
  text-align: center;
  color: #666666;
}
</style>