import Cookies from "js-cookie";

const TokenKey = "Token";

export function getCookie(username) {
  return Cookies.get(`${username}${TokenKey}`);
}

export function setCookie(username, token) {
  return Cookies.set(`${username}${TokenKey}`, token);
}

export function removeCookie(username) {
  return Cookies.remove(`${username}${TokenKey}`);
}
