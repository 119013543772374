<template>
  <div class="mfooter_page">
    <div class="navigationbar">
      <el-collapse v-model="activeName" v-for="(item, index) in productdate" :key="index">
        <el-collapse-item
          v-if="item.ftitle!='联系我们'"
          :title="item.ftitle"
          :name="index"
          style="padding-left: 4%; background-color: #fff"
        >
          <div v-if="item.ftitle == '产品入口'">
            <el-collapse
              v-model="activeNameproduct"
              v-for="(itemlist, index) in item.flist_h5"
              :key="index"
            >
              <el-collapse-item
                :title="itemlist.ftitle"
                :name="index"
                style="padding-left: 4%; background-color: #fff"
              >
                <div
                  class="navigationbar-two"
                  v-for="item_data in itemlist.flist"
                  :key="item_data.path"
                >
                  <router-link
                    @click.native="drawer = false"
                    :to="'/h5'+item_data.path"
                  >{{ item_data.name }}</router-link>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div v-else>
            <div class="navigationbar-two" v-for="(itemdata,index) in item.flist_h5" :key="index">
              <router-link @click.native="drawer = false" :to="itemdata.path">
                {{
                itemdata.ftitle
                }}
              </router-link>
            </div>
          </div>
          <!-- <div class="navigationbar-two" v-for="(itemdata,index) in productdate.flist_h5" :key="index">
            <router-link @click.native="drawer = false" :to="itemdata.path">
              {{
              itemdata.ftitle
              }}
            </router-link>
          </div>-->
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="contact-name">联系我们</div>
    <div class="contact">
      <div class="contact-left">
        <img class="images" src="../assets/acs/公众号二维码@2x.png" alt />
        <p>官网微信公众号</p>
      </div>
      <div class="contact-right">
        <p>咨询热线：(刘总监)</p>
        <p>15607125858</p>
        <p>客服邮箱：</p>
        <p>service@kplgnss.com</p>
      </div>
    </div>
    <div class="copyright">Copyright © 开普勒卫星科技（武汉）有限公司 | 鄂ICP备2021013347号-2</div>
  </div>
</template>
<script>
import { get_navi_bar } from "../axios/modules/officialwebsite.js";

export default {
  data() {
    return {
      activeName: [],
      activeNameproduct: [],

      productdate: [
        {
          flist_h5: []
        }
      ]
    };
  },
  methods: {
    async Get_navi_bar() {
      let res = await get_navi_bar();
      if (res.code == 0) {
        // console.log(res.foot);
        this.productdate = res.data.footer;
      }
    }
  },

  mounted() {
    this.Get_navi_bar();
  }
};
</script>
<style lang="scss" scoped>
.mfooter_page {
  background: #ffffff;
}

.contact-name {
  margin-left: 4%;
  font-size: 16px;
  line-height: 50px;
  font-weight: bold;
  color: #111111;
}
.navigationbar-two {
  padding-left: 4%;
  border-bottom: 1px solid #d4d4d4;
  line-height: 42px;

  color: #666666;
  font-size: 12px;
}
.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4%;
  border: 1px solid #333;
  margin: 2%;
  .contact-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-right {
    > p {
      line-height: 25px;
    }
  }
}
.images {
  width: 80%;
}
.copyright {
  margin: 0 8%;
  line-height: 30px;
  padding-bottom: 30px;
  text-align: center;

  color: #111111;
  font-size: 14px;
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
::v-deep .el-collapse-item__header {
  border-bottom: 1px solid #d4d4d4;
  // background: #ebebeb;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}
::v-deep .el-collapse {
  border-top: none;
}
</style>
