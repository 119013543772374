import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
//import Head from '../components/Head.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home/index",
    // mata: {
    //   keepAlive: false
    // }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },

  {
    path: "/GGAh5/actualtimegga",
    name: "actualtimegga",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/GGAh5/actualtimegga/index.vue"
      ),
  },
  {
    path: "/home/index",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contact.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
  },
  {
    path: "/application",
    name: "application",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/application.vue"),
  },


  {
    path: "/precisionpositioning/details",
    name: "details",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/precisionpositioning/details/index.vue"
      ),
  },
  // 产品

  {
    path: "/product",
    name: "product",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/product/index.vue"
      ),
    // mata: {
    //   keepAlive: false
    // }
  },

  {
    path: "/buygoods",
    name: "buygoods",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/buyGoods/buyGoods.vue"),
  },
  {
    path: "/newsdetail/newsxw1",
    name: "newsxw1",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw1.vue"),
  },
  {
    path: "/newsdetail/newsxw2",
    name: "newsxw2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw2.vue"),
  },
  {
    path: "/newsdetail/newsxw3",
    name: "newsxw3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw3.vue"),
  },
  {
    path: "/newsdetail/newsxw4",
    name: "newsxw4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw4.vue"),
  },
  {
    path: "/newsdetail/newsxw5",
    name: "newsxw5",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw5.vue"),
  },
  {
    path: "/newsdetail/newsxw6",
    name: "newsxw6",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw6.vue"),
  },
  {
    path: "/newsdetail/newsxw7",
    name: "newsxw7",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail/newsxw7.vue"),
  },
  // 模组

  // 产品列表
  {
    path: "/tabulation/index",
    name: "tabulation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/tabulation/index.vue"
      ),
  },
  // {
  //   path: "/moduleproduct/kpl260r",
  //   name: "kpl260r",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/moduleproduct/kpl260r.vue"
  //     ),
  // },
  // {
  //   path: "/moduleproduct/kpl490",
  //   name: "kpl490",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/moduleproduct/kpl490.vue"
  //     ),
  // },

  {
    path: "/traffic",
    name: "traffic",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/traffic.vue"),
  },
  // {
  //   path: "/homefirst",
  //   name: "homefirst",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/homefirst.vue"),
  // },
  {
    path: "/support_services",
    name: "support_services",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/support_services/index.vue"
      ),
  },
  {
    path: "/personal_center/index",
    name: "personal_center",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/personal_center/index.vue"
      ),
  },
  {
    path: "/personal_center/authentication",
    name: "authentication",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/personal_center/authentication.vue"
      ),
  },



  {
    path: "/logistics",
    name: "logistics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/logistics.vue"),
  },



  ///H5页面
  {
    path: "/h5/home/index",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/home/index.vue"),
  },
  {
    path: "/h5/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/contact.vue"),
  },
  {
    path: "/h5/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/news.vue"),
  },
  {
    path: "/h5/application",
    name: "application",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/application.vue"),
  },

  // 产品

  {
    path: "/h5/product",
    name: "product",
    component: () =>
      import(
      /* webpackChunkName: "about" */ "../views/h5/product/index.vue"
      ),
  },

  {
    path: "/h5/newsdetail/newsxw1",
    name: "newsxw1",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw1.vue"),
  },
  {
    path: "/h5/newsdetail/newsxw2",
    name: "newsxw2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw2.vue"),
  },
  {
    path: "/h5/newsdetail/newsxw3",
    name: "newsxw3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw3.vue"),
  },
  {
    path: "/h5/newsdetail/newsxw4",
    name: "newsxw4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw4.vue"),
  },
  {
    path: "/h5/newsdetail/newsxw5",
    name: "newsxw5",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw5.vue"),
  },
  {
    path: "/h5/newsdetail/newsxw6",
    name: "newsxw6",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw6.vue"),
  },
  {
    path: "/h5/newsdetail/newsxw7",
    name: "newsxw7",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/newsdetail/newsxw7.vue"),
  },
  {
    path: "/h5/support_services",
    name: "support_services2",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/h5/support_services/index.vue"
      ),
  },
  {
    path: "/h5/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/About.vue"),
  },
  {
    path: "/h5/traffic",
    name: "traffic",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/h5/traffic.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
