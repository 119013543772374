import router from "@/router/index.js";

import { setCookie } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";
import { _isMobile } from "@/utils/ismobile";
import { networkConfig } from "@/axios/networkConfig";


router.beforeEach((to, from, next) => {
  console.log("to", to, from);
  //启动进度条
  //设置标签里指定的标题

  let url = window.location.href;
  console.log("url==>", url, window.location.protocol);
  // if (window.location.protocol == "http:") {
  //   console.log(url.replace("http:", "https:"));
  //   window.location.href = url.replace("http:", "https:");
  // }
  document.title = getPageTitle(to.meta.title);
  //判断token是否存在
  //token存在判断权限
  //权限存在就访问通过,权限不存在就分配权限
  //token不存在就

  if (_isMobile()) {
    if (to.path.indexOf("/h5") == -1) {

      next({ path: "/h5" + to.path, replace: true })
    } else {
      next();
    }
  } else {
    next();
  }

});
/**
 * 全局后置钩子
 */
router.afterEach((to, from, next) => {
  console.log("全局后置钩子");
  console.log("to", to, from);
  location.replace(location.href);
  if (to.query.token) {
    setCookie(to.query.username, to.query.token);
    setCookie("username", to.query.username);
    console.log(to.query.token);
    // setCookie("token", to.query.token);
    // console.log(document.location);
    // console.log(`${document.location.href.split("?")[0]}`);
    window.location.href = `${document.location.href.split("?")[0]}`;

  }
  if (to.fullPath != from.fullPath && from.fullPath != '/') {
    console.log(to.fullPath, from.fullPath, 2222);
    location.reload()
  }
  // next();
});
