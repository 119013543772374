<template>
  <div class="head">
    <div class="head-top">
      <div class="head-top__center">
        <div></div>
        <div class="head-top__title">
          <div class="head-top__text">
            <!-- <img class="head-icon" src="../icons/svg/yuyan.svg" alt />
            选择区域/语言-->
          </div>
          <div class="head-top__text" v-if="youname == false" @click="gologin">
            <img class="head-icon" src="../icons/svg/denglu.svg" alt />登录/注册
          </div>
          <div v-if="youname == true" style="display: flex; align-items: center; cursor: pointer">
            <i class="el-icon-user-solid" style="margin-right: 5px"></i>
            <div>
              <p @click="gopersonal">{{ username }}</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="head-center">
      <div class="head-log" @click="gotohome"></div>
      <div class="head-right">
        <div
          class="head-onenavigation"
          v-for="(item, index) in headname"
          :key="index"
          @mouseover="(value = item.ftitle), (mask = true)"
          @mouseout="value = ''"
        >
          <div
            class="head-onenavigation__text"
            :class="[{ active: value == item.ftitle }, 'nav-tab']"
          >
            <router-link
              v-if="item.path"
              :class="[{ active: value == item.ftitle }, 'nav-texk']"
              :to="item.path"
            >{{ item.ftitle }}</router-link>
            <div v-else>{{ item.ftitle }}</div>
            <div class="head-twonavigation" v-if="item.ftlist!==undefined&&item.ftlist.length>1">
              <div class="head-twonavigation__li">
                <div
                  class="head-twonavigation__right"
                  v-for="itemlist in item.ftlist"
                  :key="itemlist.ftitle"
                >
                  <router-link
                    :to="{
                          path:itemlist.fpath,
                          query: { title: itemlist.ftitle },
                        }"
                    class="head-twonavigation__head"
                  >{{ itemlist.ftitle }}</router-link>
                  <div class="head-twonavigation__hr"></div>
                  <div class="head-twonavigation__foot">
                    <div
                      class="right-row"
                      v-for="list_data in itemlist.flist"
                      :key="list_data.path"
                      @mouseover="right_item_active = list_data.path"
                    >
                      <router-link
                        :to="list_data.path"
                        :class="[
                          { active: right_item_active == list_data.path },
                          'right-row--a',
                        ]"
                      >{{ list_data.name }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="head-imput">
        <!-- 搜索弹窗 -->
        <!-- <img src="../icons/svg/sousuo.svg" alt="" />
        <el-input style="border-radius: 20px" v-model="search"></el-input>-->

        <!-- <el-button type="text" @click="dialogVisible = true">111</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { get_navi_bar, Config } from "../axios/modules/officialwebsite.js";

import { Personal } from "../axios/modules/personals.js";
// import { ref } from "vue";
import { setCookie } from "@/utils/auth";
import { getCookie } from "../utils/auth";
import { mapState } from "vuex";

export default {
  data() {
    return {
      search: "",
      mask: "",
      right_item_active: "",
      isActive: "",
      value: "",
      value1: "",
      restaurants: [],
      state: "",
      timeout: null,
      right_list_arr: [],
      locationurl: "",
      url_obj: "",
      username: "",
      youname: false,

      headname: [],
      supportkpl: []
    };
  },

  methods: {
    async Personaldata() {
      let res = await Personal();

      this.username = res.fname;
      // console.log(res.fname);
      this.youname = true;
    },
    // 去往个人中心页面
    gotohome() {
      this.$router.push({ path: "/home/index" });
    },
    gopersonal() {
      this.$router.push({ path: "/personal_center/index" });
    },
    async Get_navi_bar() {
      let res = await get_navi_bar();

      if (res.code == 0) {
        // console.log(res.data.header);
        // this.headname = res.data.header.list;
        let listdata = res.data.header;
        listdata.list.map(item => {
          console.log(listdata[item.fheading], 2222222);
          item.ftlist = listdata[item.fheading];
          this.headname.push(item);
          return;
        });
        console.log(this.headname);
      }
    },
    async configlogin(local) {
      let { code, data } = await Config(local);
      console.log(data);
      if (code == 0) {
        this.locationurl = document.location.href;
        this.url_obj = this.locationurl.split("#");
        // this.url_obj = "https//kplgnss.com";
        window.location = `${data.config}?redirect-url-succ=${this.url_obj[0]}&redirect-url-name=/`;
        console.log(this.url_obj, this.locationurl);
        return;
      }
    },
    gologin() {
      this.configlogin("login");
    }
  },
  components: {
    // configlocal,
  },
  computed: {
    ...mapState(["nikeName"])
  },
  mounted() {
    // this.Personaldata();
    console.log(getCookie(getCookie("username")), 3333);
    // this.username = this.nikeName;
    // this.username = getCookie("username");
    // if (this.username != "") {
    //   console.log(1111);
    //   this.youname = true;
    // } else {
    //   this.youname = false;
    //   console.log(222);
    // }

    this.Get_navi_bar();
  },
  watch: {
    $route(toPath, fromPath) {
      console.log(toPath, fromPath, "00999________--");
      console.log(!getCookie(getCookie("username")));
      if (getCookie(getCookie("username"))) {
        // if (getCookie(getCookie("username")) != "") {
        this.Personaldata();
        //   console.log(getCookie(getCookie("username")), 4567);
        // }
        // this.youname = false;
      } else {
        // console.log(res.fname);
        this.youname = false;
        // this.youname = true;
      }
    }
  }

  //     if (getCookie("username")) {
  //       console.log(1111);
  //       this.value = getCookie(getCookie("username"));
  //       console.log(this.value, 778888);
  //       // this.youname = getCookie("username");
  //       console.log(this.username, this.youname, 2222222);
  //       if (this.value != "undefined" && this.value != "") {
  //         this.youname = true;
  //         this.username = getCookie("username");
  //         console.log(this.value, 2222);
  //       } else {
  //         this.youname = false;
  //         this.username = "";
  //         console.log(333);
  //       }
  //     }
  //   },
  //   nikeName(newData, oldData) {
  //     this.username = newData;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.head {
  // width: calc(100% - 30px);
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100px;
  background: #ffff;
  font-size: 18px;
  left: 0;
  top: 0;
}
.head-top {
  // width: 1320px;
  background: #004598;
  height: 25px;
  color: #fff;
  font-size: 12px;
  .head-top__center {
    width: 1320px;
    // height: 75px;
    display: flex;
    margin: 0 auto;
    height: 25px;
    justify-content: space-between;
    align-items: center;
    .head-top__title {
      display: flex;
      width: 236px;
      justify-content: space-between;
      align-items: center;

      color: #ffffff;
      font-size: 0.9em;
      .head-top__text {
        display: flex;
        align-items: center;
        cursor: pointer;

        .head-icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}
.head-center {
  width: 1320px;
  height: 75px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .head-log {
    height: 47.5px;
    width: 231px;
    background: url("../assets/image/headimg/KPL_logo.png") center no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-right: 35px;
  }

  .head-right {
    width: 552px;
    height: 100%;
    // background: red;

    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-onenavigation {
      // width: 552px;
      line-height: 50px;
      // padding: 20px;
    }
    .head-onenavigation__text {
      height: 50px;
    }
    .nav-tab.active {
      box-sizing: border-box;
      color: #2271f7;
      border-bottom: 2px solid #1890ff;
    }
    .nav-texk.active {
      color: #2271f7;
    }
    .head-twonavigation {
      height: 0; // 使menu从上往下平滑弹出
      width: 100vw;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: #ffffff;

      border-radius: 0 0 2px 2px;
      overflow: hidden; // 让li的内容隐藏

      .head-twonavigation__li {
        width: 1320px;
        // height: ;
        margin: 0 auto;

        font-size: 14px;
        // color: #34364e;
        line-height: 50px;
        text-align: center;

        list-style-type: none;
        text-decoration: none;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        // justify-content: flex-start;

        .head-twonavigation__right {
          display: flex;
          flex-direction: column;

          width: 200px;
          margin-right: 10px;
          .head-twonavigation__head {
            font-size: 1.25em;
            margin-top: 20px;

            text-align: left;
            // padding: 0 50px;
            color: #333333;
            font-weight: bold;

            font-family: Source Han Sans CN-Regular;
          }
          .head-twonavigation__hr {
            height: 1px;
            background: #e6e6e6;
          }

          .head-twonavigation__foot {
            margin-top: 20px;
            height: 202px;
            width: 180px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            text-align: center;
            // padding: 0 50px;

            .right-row {
              font-size: 0.9em;
              height: 25.3px;
              // height: 20px !important;
              line-height: 27px;
              margin-bottom: 15px;
              min-width: 80px;
              text-align-last: left;
              color: #333;

              .right-row--a {
                font-size: 14px;
              }
            }
          }
        }
      }
      .head-twonavigation__li:hover {
        color: #2271f7;
      }
    }
  }
}
.head-twonavigation__mask {
  position: absolute;
  margin-top: 100px;
  height: 100vh;
  width: 100vw;
  background: url(../assets/mask.png);
  left: 50%;
  transform: translateX(-50%);
}
.head-onenavigation:hover {
  // .nav-tab {
  //   border-bottom: 4px solid #1890ff;
  //   // color: #2271f7;
  // }

  .head-twonavigation {
    z-index: 999;
    transition: height 0.3s; // 鼠标移出时menu瞬间消失，故将transition加在hover里
    // min-height: 30vh;
    height: 65vh; // 使menu从上往下平滑弹出
    width: 100vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
.active.right-row--a {
  color: #2271f7;
}
// 搜索弹窗样式
.head-imput {
  display: flex;
  align-items: center;
  width: 179px;
  justify-content: space-between;
  > img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}
::v-deep .el-input__inner {
  width: 147px;
  border-radius: 20px;
}
</style>
